<template>
	<div>
		<div class="center center1">
			<!-- 轮播 -->
			<div class="lb">
				<div>
					<div style="overflow:hidden; position:relative; width:712px">
						<el-carousel indicator-position="outside">
							<el-carousel-item v-for="(item,key) in images" :key="key">
								<a @click="picture(item)">
									<img :src="item.img" style="width: 713px;cursor: pointer;" alt=""></a>
								<div class="img1">{{item.title}}</div>
							</el-carousel-item>
						</el-carousel>
					</div>
				</div>
			</div>

			<div class="center1-lm">
				<div class="center1-lm1">
					<!-- <a href="#" class="a1" @mouseover="so(0)">协会信息</a>
					<span></span> -->
					<a href="/information">行业资讯</a>
				</div>
				<div class="center1-lm2">
					<ul>
						<li v-for="(item,key) in dataInfor"
							style="overflow: hidden;white-space: nowrap;text-overflow:ellipsis;margin-top: 20px;font-size: 20px;"
							:key="key">
							<el-link :underline="false" :title="item.title" @click="jumpclick(item)">
								{{item.title|ellipsis}}
							</el-link>
						</li>
					</ul>
				</div>
			</div>
			<div class="clear"></div>
		</div>

		<div class="center">
			<div class="center2-y">
				<div class="center2-y">
					<div class="center2-left">
						<a href="/observation" class="au">行业观察</a>
					</div>
					<div class="cenetr21 ">
						<div style="width:100%;">
							<ul>
								<li v-for="(item,key) in industryObser" :key="key">
									<el-link :underline="false" :title="item.title" @click="watchclick(item)">
										{{item.title|ellipsis}}
									</el-link>
									<span style="line-height:39px;">{{item.creatTime}}</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="center2-r center2-y">
				<div class="center2-left">
					<a href="/case">行业案例</a>
				</div>
				<div class="cenetr21" style="width:100%;">
					<ul>
						<li v-for="(item,key) in industryCase" :key="key">
							<el-link :underline="false" :title="item.title" @click="caseclick(item)">
								{{item.title|ellipsis}}
							</el-link>
							<span style="line-height:39px;">{{item.creatTime}}</span>
						</li>
					</ul>
				</div>
				<el-button class="zs" style="line-height: 0px;background-color: #409eff" @click="certificate" disabled >证书查询</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dataInfor: [],
				societydata: [],
				industryInfor: [],
				industryObser: [],
				industryCase: [],
				images: [],
			}
		},
		methods: {
			prevHandler() {
				this.currentIndex--;
				if (this.currentIndex === 0) {
					this.currentIndex = 3
				}
			},
			nextHandler() {
				this.currentIndex++;
				if (this.currentIndex === 3) {
					this.currentIndex = 0
				}
			},
			picture(data) {
				this.$router.push({
					path: "/information",
					query: {
						firstdata: data
					}
				})
			},
			jumpclick(data) {
				this.$router.push({
					path: "/information",
					query: {
						firstdata: data
					}
				})
			},
			watchclick(data) {
				this.$router.push({
					path: "/observation",
					query: {
						firstdata: data
					}
				})
			},
			caseclick(data) {
				this.$router.push({
					path: "/case",
					query: {
						firstdata: data
					}
				})
			},
			certificate() {
				this.$message({
					message: "功能点正在开发中",
					type: 'false'
				})
			}
		},
		created() {
			let that = this;
			this.$post("/industry/chartindustry", {
				headline: "行业资讯",
				size: "10"
			}).then(resp => {
				that.dataInfor = resp.dataList;
				resp.dataList.forEach(item => {
					if (item.img!=null && item.img.length > 0){
						that.images.push(item);
					}
				})
			});

			this.$post("/industry/chartindustry", {
				headline: "行业观察",
				size: "6"
			}).then(resp => {
				this.industryObser = resp.dataList
			});

			this.$post("/industry/chartindustry", {
				headline: "行业案例",
				size: "3"
			}).then(resp => {
				this.industryCase = resp.dataList
			});
		},
		filters: {
			ellipsis(value) {
				if (!value) return "";
				if (value.length > 24) {
					return value.slice(0, 24) + "...";
				}
				return value;
			},
		},
	}
</script>

<style>
	@import '../../assets/css/index.css';

	.el-link {
		justify-content: left;
		left: -10px;
	}

	.cenetr21 ul li span {
		font-style: normal;
		display: block;
		float: right;
		font-size: 15px;
		line-height: 33px;
	}

	.el-carousel__item h3 {
		color: #475669;
		font-size: 18px;
		opacity: 0.75;
		line-height: 300px;
		margin: 0;
	}

	.el-carousel__item:nth-child(2n) {
		background-color: #99a9bf;
	}

	.el-carousel__item:nth-child(2n+1) {
		background-color: #d3dce6;
	}

	.img1 {
		position: absolute;
		bottom: 0;
		height: 50px;
		width: 100%;
		line-height: 50px;
		color: #fff;
		text-indent: 2em;
		background: url(../../assets/img/lb-bg.png)center
	}
</style>
